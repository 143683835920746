import React, { useEffect, useRef } from "react";
import * as styles from "./PrimoShowcase.module.css";
import { ComponentProps, ComponentWidth, CtaProps } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { Helmet } from "react-helmet";
import { Cta } from "../../elements/Cta/Cta";

interface Props extends ComponentProps, ComponentWidth, CtaProps {
  titleText: string;
  titleLink: string;
  defaultThumbnail: Image;
  queryUrl: string;
}

export const PrimoShowcase: React.FC<Props> = ({
  titleText,
  titleLink,
  defaultThumbnail,
  queryUrl,
  ctaHref,
  ctaLabel,
  ctaTarget,
  ctaAriaLabel,
  ...props
}) => {
  const carouselContainer = useRef(null) as
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;

  useEffect(() => {
    if (carouselContainer?.current) {
      const existingEl =
        carouselContainer.current.querySelector("search-carousel");

      if (existingEl) return;

      const el = document.createElement("search-carousel");
      el.setAttribute("titleText", titleText);
      el.setAttribute("titleLink", titleLink);
      el.setAttribute("defaultThumbnailUrl", defaultThumbnail.item.url);
      el.setAttribute("searchUrl", queryUrl);
      props.width === "contained"
        ? el.setAttribute("style", "--width-param: 1240px;")
        : el.setAttribute(
            "style",
            "--width-param: calc(100% - 4 * var(--site-padding));"
          );
      carouselContainer.current.appendChild(el);
    }
  }, []);

  return (
    <SectionWrapper {...props}>
      <Helmet>
        <script
          type="module"
          src="/scripts/discovery-showcase.bundled.js"
          id="primo-showcase-script"
        ></script>
      </Helmet>
      <div className={styles.primoShowcase}>
        <div ref={carouselContainer}></div>
        {ctaLabel && ctaHref && (
          <div className={styles.cta}>
            <Cta
              label={ctaLabel}
              target={ctaTarget}
              href={ctaHref}
              ariaLabel={ctaAriaLabel}
              data-kontent-element-codename="link"
            />
          </div>
        )}
      </div>
    </SectionWrapper>
  );
};
